$primary: #344d74;
$secondary: #bd5660;
$light: #dddddd;
$dark: #373a3c;

$font-family-sans-serif: 'Raleway', sans-serif;
$link-color: $secondary;

@import '~bootstrap/scss/bootstrap.scss';

:focus-visible {
  outline: none;
  box-shadow: 0 0 0 3px white, 0 0 0 6px black !important;
}

@media (min-width: 992px) {
  .header {
    position: fixed;
    right: 0;
    left: 0;
    z-index: 1030;
  }
  .app-container {
    padding-top: 62px;
  }
}

.header-cap {
  display: none;
}

.header {
  top: 0 !important;
  font-size: 1.25rem;
  .navbar-text {
    font-weight: 600;
    color: $primary;
    padding-left: .5rem;
    padding-right: .5rem;
  }
  .navbar-nav .nav-link.active {
    color: $secondary;
    text-decoration: underline;
  }
  #nav-dropdown-user > span > span {
    display: none;
  }
}

.resizing-container {
  padding: 0;
}

.login-panel.login-panel {
  &:hover {
    box-shadow: $primary 0 0 15px;
  }
  margin-top: 0.75rem;
  margin-bottom: 2.5rem;
  padding: 0;
  .login-col-img {
    img {
      object-fit: cover;
      border-radius: 0 1.6rem 1.6rem 0;
      min-height: 100%;
      max-height: 265px;
      min-width: 100%;
      max-width: 100%;
    }
  }
  .login-logo-img {
    display: block;
    max-height: 190px;
    max-width: 100%;
    margin: auto;
    padding: 15px;
  }
  .btn.btn-primary::before {
    content: 'Center '
  }
}

.title-toolbar.title-toolbar {
  margin-bottom: 2.5rem;
  .col-navbar {
    .navbar {
      background-color: $primary;
      .btn:not(.dropdown-item) {
        color: $white;
        padding: 0 8px;
      }
      .navbar-brand {
        padding-left: 0.5rem;
      }
      .navbar-text {
        padding: 0;
      }
    }
  }
  .logo img {
    width: 80%
  }
}

.document-panel.document-panel {
  .action-bar {
    background-color: white;
    position: sticky;
    top: 73px;
  }
}

.story-grid.story-grid {
  &:hover {
    box-shadow: $primary 0 0 15px;
  }
  .rdw-editor-main {
    height: 460px;
    overflow-y: auto;
    .DraftEditor-editorContainer, .DraftEditor-root, .public-DraftEditor-content {
      height: unset;
    }
  }
  .btn.btn-outline-secondary {
    border-color: $light;
  }
}

.story-panel.story-panel {
  position: relative;
  .btn-link {
    text-decoration: underline;
    position: absolute;
    top: 0;
    right: 0;
  }
}

@media (min-width: 768px) {
  .slider-content {
    .files {
      max-height: calc(100vh - 160px);
      overflow-y: auto;
    }
  }
}

.rdw-link-decorator-wrapper {
  a, span {
    color: $secondary;
  }
  .rdw-link-decorator-icon {
    display: none;
  }
}

.rendered-file-browser.rendered-file-browser {
  .files table {
    margin-bottom: 0;
  }
  a, .item-actions label {
    cursor: pointer;
    color: $dark;
  }
}

.footer {
  .contact-us {
    position: fixed;
    top: 1.3rem;
    right: 1rem;
    @media (min-width: 992px) {
      right: 5rem;
    }
    z-index: 1100;
    font-size: 1rem;
    .btn-link {
      font-size: 1rem;
    }
  }
}
